import React, { ReactNode } from 'react'

import { ThemeProvider } from 'styled-components'
import { deepmerge } from 'deepmerge-ts'

type Prop = {
  overrides?: object
  children: ReactNode
}

const colors = {
  // Primary
  blue: '#005EEB',
  blueHover: '#0052E7',
  blueClick: '#0046E4',
  navyDark: '#00234F',
  navyLight: '#042754',
  navy90: 'rgba(0, 35, 79, 0.9)',
  charcoal: '#1A1A1A',
  charcoal80: 'rgba(55, 53, 48, 0.8)',
  charcoal60: 'rgba(55, 53, 48, 0.6)',
  charcoal40: 'rgba(55, 53, 48, 0.4)',
  charcoal30: 'rgba(55, 53, 48, 0.3)',
  charcoal20: 'rgba(55, 53, 48, 0.2)',
  charcoal10: 'rgba(55, 53, 48, 0.1)',
  charcoal5: 'rgba(55, 53, 48, 0.05)',
  charcoal3: 'rgba(55, 53, 48, 0.03)',
  charcoal80Solid: '#5B5955',
  charcoal60Solid: '#878683',
  charcoal40Solid: '#AFAEAC',
  charcoal20Solid: '#D7D7D6',
  charcoal15Solid: '#E1E1E0',
  charcoal10Solid: '#EBEBEA',
  charcoal5Solid: '#F5F5F5',
  charcoal3Solid: '#F9F9F9',
  white: '#FFFFFF',
  white90: 'rgba(255, 255, 255, 0.9)',
  white80: 'rgba(255, 255, 255, 0.8)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white10: 'rgba(255, 255, 255, 0.1)',
  paleBlue: '#EFF6FE',
  aquamarine: '#23D8AC',
  aquamarine20: 'rgba(35, 216, 172, 0.2)',
  aquamarineHover: '#21C6A6',
  aquamarineClick: '#17BC9C',

  // Secondary
  orange: '#F06F26',
  orangeText: '#792E04',
  orange20: 'rgba(240, 111, 38, 0.20)',
  purple: '#4654D5',
  purpleText: '#1D324D',
  purple20: 'rgba(70, 84, 213, 0.20)',
  green: '#00A853',
  greenText: '#0F503D',
  green20: 'rgba(0, 168, 83, 0.20)',
  teal: '#0A82A2',
  teal10: 'rgba(10, 130, 162, 0.10)',
  tealText: '#1D324D',
  teal20: 'rgba(10, 130, 162, 0.20)',
  red: '#C32C2F',
  redText: '#760A0D',
  red20: 'rgba(183, 2, 5, 0.20)',
  errorLight: '#E60004',
  errorDark: '#CF6679',
  error40: '#F5999B',

  // Misc
  mask: 'rgba(90, 97, 107, 0.5)',
} as const

const schemeColor = {
  light: colors.white,
  dark: colors.navyDark,
} as const

const typography = {
  mega: {
    desktop: {
      fontSize: '5.5rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '5.5rem',
      letterSpacing: '-0.22rem',
    },
    mobile: {
      fontSize: '4.5rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '4.5rem',
      letterSpacing: '-0.18rem',
    },
  },
  h1: {
    desktop: {
      fontSize: '3.75rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '4.125rem',
      letterSpacing: '-0.113rem',
    },
    mobile: {
      fontSize: '2.5rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '2.875rem',
      letterSpacing: '-0.075rem',
    },
  },
  h2: {
    desktop: {
      fontSize: '2.75rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '3.25rem',
      letterSpacing: '-0.0825rem',
    },
    mobile: {
      fontSize: '1.75rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '2.25rem',
      letterSpacing: '-0.0525rem',
    },
  },
  h3: {
    desktop: {
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '2.5rem',
      letterSpacing: '-0.06rem',
    },
    mobile: {
      fontSize: '1.375rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '1.875rem',
      letterSpacing: '-0.04125rem',
    },
  },
  h4: {
    desktop: {
      fontSize: '1.375rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '1.875rem',
      letterSpacing: '-0.0275rem',
    },
    mobile: {
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '1.625rem',
      letterSpacing: '-0.01125rem',
    },
  },
  h5: {
    desktop: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 450,
      lineHeight: '1.375rem',
      letterSpacing: 0,
    },
    mobile: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 450,
      lineHeight: '1.25rem',
      letterSpacing: 0,
    },
  },
  'with-quote': {
    desktop: {
      fontSize: '1.375rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '1.875rem',
      letterSpacing: '-0.0275rem',
    },
    mobile: {
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '1.625rem',
      letterSpacing: '-0.0225rem',
    },
  },
  'body-400': {
    desktop: {
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.875rem',
      letterSpacing: 0,
    },
    mobile: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.75rem',
      letterSpacing: 0,
    },
  },
  'body-550': {
    desktop: {
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '1.875rem',
      letterSpacing: 0,
    },
    mobile: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '1.75rem',
      letterSpacing: 0,
    },
  },
  'body-small-400': {
    desktop: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.75rem',
      letterSpacing: 0,
    },
    mobile: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.5rem',
      letterSpacing: 0,
    },
  },
  'body-small-550': {
    desktop: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '1.75rem',
      letterSpacing: 0,
    },
    mobile: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 550,
      lineHeight: '1.5rem',
      letterSpacing: 0,
    },
  },
  button: {
    desktop: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 530,
      lineHeight: '1.375rem',
      letterSpacing: 0,
    },
    mobile: {
      fontSize: '0.9375rem',
      fontStyle: 'normal',
      fontWeight: 530,
      lineHeight: '1.25rem',
      letterSpacing: 0,
    },
  },
} as const

export const breakpointNumbers = {
  xs: 23.5, // 376px
  sm: 46.563, // 745px
  md: 64.063, // 1025px
  lg: 80.063, // 1281px
  xl: 97.563, // 1561px
} as const

const breakpoints = {
  /**
   * Extra small devices, above 375px.
   */
  xs: `(min-width: ${breakpointNumbers.xs}em)`,
  /**
   * Small devices, above 744px.
   */
  sm: `(min-width: ${breakpointNumbers.sm}em)`,
  /**
   * Medium devices, above 1024px.
   */
  md: `(min-width: ${breakpointNumbers.md}em)`,
  /**
   * Large devices, above 1280px.
   */
  lg: `(min-width: ${breakpointNumbers.lg}em)`,
  /**
   * Extra large devices, above 1560px.
   */
  xl: `(min-width: ${breakpointNumbers.xl}em)`,
} as const

/** New Breakpoints */

export const newBreakpointNumbers = {
  xs: 23.5, // 376px
  sm: 46.563, // 745px
  md: 64.063, // 1025px
  lg: 112.5, // 1800px
} as const

const newBreakpoints = {
  /**
   * Extra small devices, above 375px.
   */
  xs: `(min-width: ${newBreakpointNumbers.xs}em)`,
  /**
   * Small devices, above 744px.
   */
  sm: `(min-width: ${newBreakpointNumbers.sm}em)`,
  /**
   * Medium devices, above 1024px.
   */
  md: `(min-width: ${newBreakpointNumbers.md}em)`,
  /**
   * Large devices, 1800px and above.
   */
  lg: `(min-width: ${newBreakpointNumbers.lg}em)`,
} as const

const transitions = {
  default: 'all 0.25s ease-in-out',
  width: 'width 0.25s ease-in-out',
  color: 'color 0.25s ease-in-out',
  background: 'background 0.25s ease-in-out',
  transform: 'transform 0.5s ease-in-out',
  checkbox: 'all 0.5s ease-in-out',
  'menu-button': 'all 0.18s ease-in-out',
  'input-border': 'border-color 0.25s ease-in-out',
  carousel: { opacity: 'opacity 0.5s ease-in-out', slider: 'all 800ms cubic-bezier(0.165, 0.99, 0.44, 1)' },
  switch: {
    transform: 'transform 0.25s ease-in-out',
    color: 'color 0.2s ease-in-out',
  },
  toggle: {
    background: 'background 0.25s ease-in-out',
    fill: 'fill 0.25s ease-in-out',
  },
} as const

const zIndices = {
  header: 100,
  mask: 1000,
  dropdown: 300,
} as const

export const theme = {
  breakpoints,
  newBreakpoints,
  colors,
  schemeColor,
  transitions,
  typography,
  zIndices,
} as const

export type ThemeType = typeof theme

export type ColorType = keyof typeof theme.colors

export type BreakpointType = keyof typeof theme.breakpoints

export type SchemeType = keyof typeof theme.schemeColor

const Theme = ({ overrides = {}, children }: Prop) => {
  const themeObject = deepmerge(theme, overrides) as typeof theme

  return <ThemeProvider theme={themeObject}>{children}</ThemeProvider>
}

export default Theme
