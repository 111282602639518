import Bold from './Bold'
import Bold2 from './Bold2'
import Medium from './Medium'
import Medium2 from './Medium2'
import Megabold from './Megabold'
import Megabold2 from './Megabold2'
import Regular from './Regular'
import Regular2 from './Regular2'
import RegularSfMono from './RegularSfMono'
import Semibold from './Semibold'
import Semibold2 from './Semibold2'
import Variable from './Variable'
import { css } from 'styled-components'

export const INTER_FONTS = {
  BOLD: Bold,
  BOLD2: Bold2,
  MEDIUM: Medium,
  MEDIUM2: Medium2,
  MEGABOLD: Megabold,
  MEGABOLD2: Megabold2,
  REGULAR: Regular,
  REGULAR2: Regular2,
  SEMIBOLD: Semibold,
  SEMIBOLD2: Semibold2,
  VARIABLE: Variable,
}

export const SFMONO_FONTS = {
  REGULAR: RegularSfMono,
}

export default css`
  @font-face {
    font-family: 'Inter';
    src: url('${INTER_FONTS.REGULAR2}') format('woff2'), url('${INTER_FONTS.REGULAR}') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('${INTER_FONTS.MEDIUM2}') format('woff2'), url('${INTER_FONTS.MEDIUM}') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('${INTER_FONTS.VARIABLE}') format('woff2'), url('${INTER_FONTS.VARIABLE}') format('woff');
    font-weight: 530;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('${INTER_FONTS.VARIABLE}') format('woff2'), url('${INTER_FONTS.VARIABLE}') format('woff');
    font-weight: 550;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('${INTER_FONTS.SEMIBOLD2}') format('woff2'), url('${INTER_FONTS.SEMIBOLD}') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('${INTER_FONTS.BOLD2}') format('woff2'), url('${INTER_FONTS.BOLD}') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url('${INTER_FONTS.MEGABOLD2}') format('woff2'), url('${INTER_FONTS.MEGABOLD}') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sf Mono';
    src: url('${SFMONO_FONTS.REGULAR}') format('woff');
    font-weight: 400;
    font-style: normal;
  }
`
