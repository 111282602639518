import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'

type GlobalContexts = {
  setGlobalData: (state: Partial<GlobalData>) => void

  showCookieBanner: boolean
  setShowCookieBanner: Dispatch<SetStateAction<boolean>>

  showManageCookiesModal: boolean
  setShowManageCookiesModal: Dispatch<SetStateAction<boolean>>
}

type GlobalData = {
  showCookieBanner: boolean
  showManageCookiseModal: boolean
}

const GlobalContexts = createContext<GlobalContexts>({
  setGlobalData: () => null,
  showCookieBanner: false,
  setShowCookieBanner: () => null,

  showManageCookiesModal: false,
  setShowManageCookiesModal: () => null,
})

export const GlobalContextsProvider = ({ children }: { children: ReactNode }) => {
  const [showCookieBanner, setShowCookieBanner] = useState(false)
  const [showManageCookiesModal, setShowManageCookiesModal] = useState(false)

  const setGlobalData = ({ showCookieBanner }: Partial<GlobalData> = {}) => {
    if (showCookieBanner !== undefined) setShowCookieBanner(showCookieBanner)
  }

  return (
    <GlobalContexts.Provider
      value={{
        setGlobalData,
        showCookieBanner,
        setShowCookieBanner,
        showManageCookiesModal,
        setShowManageCookiesModal,
      }}
    >
      {children}
    </GlobalContexts.Provider>
  )
}

export const useGlobalContexts = () => useContext(GlobalContexts)

export default GlobalContextsProvider
