import type { AppProps } from 'next/app'
import GlobalContextsProvider from 'library/layout/GlobalContextsProvider'
import GlobalStyle from 'theme/GlobalStyle'
import Head from 'next/head'
import React from 'react'
import ThemeProvider from 'theme/ThemeProvider'

const Onetrace = ({ Component, pageProps }: AppProps) => (
  <>
    <Head>
      <meta name='viewport' content='width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no' />
    </Head>

    <GlobalContextsProvider>
      <ThemeProvider>
        <GlobalStyle />
        <Component {...pageProps} />
      </ThemeProvider>
    </GlobalContextsProvider>
  </>
)

export default Onetrace
