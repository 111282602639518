import { createGlobalStyle, css } from 'styled-components'

import fonts from './fonts'
import normalize from './Normalize'

const globalStyleCss = css`
  ${normalize}
  ${fonts}

  * {
    margin: 0;
    padding: 0;

    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-variation-settings: 'opsz' 14;
  }

  body {
    font-family: 'Inter', sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;

    ${({ theme }) => theme.typography['body-400'].mobile}

    @media ${({ theme }) => theme.breakpoints.md} {
      ${({ theme }) => theme.typography['body-400'].desktop}
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h1 {
    ${({ theme }) => theme.typography.h1.mobile}

    @media ${({ theme }) => theme.breakpoints.md} {
      ${({ theme }) => theme.typography.h1.desktop}
    }
  }

  h2 {
    ${({ theme }) => theme.typography.h2.mobile}

    @media ${({ theme }) => theme.breakpoints.md} {
      ${({ theme }) => theme.typography.h2.desktop}
    }
  }

  h3 {
    ${({ theme }) => theme.typography.h3.mobile}

    @media ${({ theme }) => theme.breakpoints.md} {
      ${({ theme }) => theme.typography.h3.desktop}
    }
  }

  h4 {
    ${({ theme }) => theme.typography.h4.mobile}

    @media ${({ theme }) => theme.breakpoints.md} {
      ${({ theme }) => theme.typography.h4.desktop}
    }
  }

  h5 {
    ${({ theme }) => theme.typography.h5.mobile}

    @media ${({ theme }) => theme.breakpoints.md} {
      ${({ theme }) => theme.typography.h5.desktop}
    }
  }

  strong {
    font-weight: 550;
  }
`

const GlobalStyle = createGlobalStyle`${globalStyleCss}`

export default GlobalStyle
