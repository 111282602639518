import React, { ReactNode } from 'react'

import Theme from './index'

type Props = {
  children: ReactNode
  overrides?: object
}

const ThemeProvider = ({ overrides, children }: Props) => <Theme overrides={overrides}>{children}</Theme>

export default ThemeProvider
